import React, { Component } from "react"
import styled from "styled-components"
// import Bowser from "bowser"

import videojs from "video.js"
import "video.js/dist/video-js.css"

import { liveStreamSrc } from "src/modules/constants"
// import { isWindowDefined } from "src/modules/utils"

// const getBrowserAgent = () => {
//   const browserAgent = Bowser.getParser(window.navigator.userAgent).parsedResult

//   return browserAgent
// }

class VideoPlayer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      displayClickThroughWrapper: false,
    }
  }

  componentDidMount() {
    // const self = this
    this.player = videojs(this.videoNode, this.props)

    // if (isWindowDefined()) {
    //   // const browser = getBrowserAgent()
    //   // const { platform } = browser
    //   // const isDesktopDevice = platform.type === "desktop"
    //   // if (isDesktopDevice) {
    //   //     this.player.ima(this.props.imaOptions)
    //   //     this.player.on('adsready', function() {
    //   //         const player = this
    //   //         window.player = this
    //   //         const ALL_ADS_COMPLETED = window && window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED
    //   //         const AD_ERROR = window && window.google.ima.AdErrorEvent.Type.AD_ERROR
    //   //         self.setState({ displayClickThroughWrapper: true })
    //   //         this.ima.addEventListener(ALL_ADS_COMPLETED, function() {
    //   //             player.play()
    //   //             self.setState({ displayClickThroughWrapper: false })
    //   //         })
    //   //         this.ima.addEventListener(AD_ERROR, function() {
    //   //             //NTS: Ad proper error handling here.
    //   //             player.play();
    //   //         })
    //   //     })
    //   // }
    // }
  }

  componentWillUnmount() {
    if (this.player) this.player.dispose()
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856

  render() {
    // const { displayClickThroughWrapper } = this.state

    return (
      <StyledVideoPlayer>
        {/* {displayClickThroughWrapper && <ClickThroughWrapper></ClickThroughWrapper>} */}
        <VideoContent data-vjs-player>
          <VideoElement
            ref={node => (this.videoNode = node)}
            className="video-js"
            id="video"
            controls
            muted
            playsinline
            autoplay
          >
            <source src={liveStreamSrc} type="application/x-mpegURL" />
          </VideoElement>
        </VideoContent>
      </StyledVideoPlayer>
    )
  }
}

export default VideoPlayer

const StyledVideoPlayer = styled.div`
  height: auto;
  position: relative;
  width: 100%;

  @media (max-width: 600px) {
    margin-top: 0;
  }

  #video_ima-ad-container.video_ima-ad-container ima-ad-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  :hover {
    #video_ima-controls-div {
      height: 3.7em;
    }
  }
`

// const ClickThroughWrapper = styled.div`
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 95%;
//   position: absolute;
//   pointer: cursor;
//   z-index: 2000;
// `

const VideoContent = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`

const VideoElement = styled.video`
  margin: 0 auto;

  &.video-js .vjs-tech {
    position: relative;
  }

  &.video-dimensions {
    max-width: 1100px !important;
  }

  @media (max-width: 1300px) {
    &.video-dimensions {
      max-width: 900px !important;
    }
  }
`
