import React, { useEffect, useState } from "react"
import styled from "styled-components"

import VideoPlayer from "src/components/common/VideoPlayer"

import {
  liveStreamSrc,
  liveStreamType,
  springServeVast,
} from "src/modules/constants"

import FSNCanadaSlate from "/src/assets/images/FSN_Canada_Slate.jpg"
import GlobalSlate from "/src/assets/images/Global_Slate.jpg"
import { StaticImage } from "gatsby-plugin-image"

const videoJSOptions = {
  autoplay: true,
  controls: true,
  fill: true,
  liveui: true,
  responsive: true,

  sources: [
    {
      src: liveStreamSrc,
      type: liveStreamType,
    },
  ],
}

const imaOptions = {
  adTagUrl: springServeVast,
  showCountdown: true,
  showControlsForJSAds: true,
  adsRenderingSettings: {
    uiElements: ["countdown", "adAttribution"],
  },
}

// const settings = {
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   variableWidth: true,
//   responsive: [
//     {
//       breakpoint: 600,
//       settings: {
//         arrows: false,
//         centerMode: true,
//         slidesToShow: 2,
//       },
//     },
//   ],
// }

const HeroSection = ({ title, subtitle, videoUrl, videoAlt }) => {
  // Check geolocation for correct stream
  const [currentCountry, setCurrentCountry] = useState("")

  // useEffect(() => {
  //   if (window && window.location.search.includes("spoofgeo")) {
  //     const parameters = new URLSearchParams(window.location.search)
  //     setCurrentCountry(parameters.get("spoofgeo"))
  //   } else {
  //     const locationApi = "https://dev-api.fubo.tv/v3/location"
  //     fetch(locationApi, {
  //       method: "GET",
  //     })
  //       .then(res => res.json())
  //       .then(response => {
  //         setCurrentCountry(response.country_code)
  //         if (window)
  //           window["fuboLocationData"] = {
  //             country_code: response.country_code,
  //             country_name: response.country_name,
  //           }
  //       })
  //       .catch(error => console.log(error))
  //   }
  // }, [])

  const usTerritoryCodes = [
    "USA", // United States
    "PRI", // Puerto Rico
    "GUM", // Guam
    "UMI", // United States Minor Outlying Islands
    "VIR", // Virgin Islands U.S.
    "ASM", // American Samoa
    "MNP", // Northern Mariana Islands
  ]

  return (
    <StyledHeroSection>
      {/* <div className="hero__background">
        <StaticImage
          src="../../../../../src/assets/images/night_sky_bg.jpg"
          alt="BEDTIME STORIES WITH RYAN"
          loading="eager"
          // placeholder="blurred"
        />
      </div> */}
      <HeaderWrapper>
        <h1 className="hero__title">{title}</h1>
        <h3 className="hero__subtitle">{subtitle}</h3>
        {/* <p className="hero__paragraph">
          Classic and original shows, movies and commercials created to lower
          the blood pressure of Ryan Reynolds and viewers like you
        </p> */}
      </HeaderWrapper>
      {/* <VideoWrapper>
        {usTerritoryCodes.includes(currentCountry) ? (
          <VideoPlayer {...videoJSOptions} imaOptions={imaOptions} />
        ) : (
          currentCountry !== "" && (
            <VideoSlate>
              <img
                src={GlobalSlate}
                alt="This Content is Unavailable. You are outside of the region where this is available."
              />
            </VideoSlate>
          )
        )}
      </VideoWrapper> */}
      <VideoSlate>
        <iframe
          src={videoUrl}
          title={videoAlt}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          style={{
            aspectRatio: "16 / 9",
            width: "100%",
          }}
        ></iframe>
      </VideoSlate>
    </StyledHeroSection>
  )
}

export default HeroSection

const VideoSlate = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 1300px) {
    max-width: 900px;
  }
  div.blank-slate {
    width: 100%;
    height: 0;
    padding-bottom: calc(100% * 9 / 16);
  }
  img {
    width: 100%;
  }
`

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;

  @media (max-width: 900px) {
    margin-top: 1em;
  }
`

const StyledHeroSection = styled.section`
  position: relative;
  padding: 50px 0;
  color: #fff;

  @media (max-width: 767px) {
    padding: 35px 20px;
  }

  > * {
    z-index: 2;
    position: relative;
  }

  .hero__background {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${"" /* background: rgba(0, 0, 0, 0.5); */}
    div {
      width: 100%;
      height: 100%;
      ${"" /* object-fit: cover; */}
    }
  }

  .hero__title {
    font-weight: 800;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0;
    color: rgba(255, 58, 3, 1);
    font-size: 2.4em;
    @media (max-width: 767px) {
      font-size: 2em;
    }
    span {
      position: absolute;
      top: -999px;
      left: -999px;
    }
  }

  .hero__subtitle {
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.3em;
    @media (max-width: 767px) {
    }
  }
  .hero__paragraph {
    ${"" /* font-size: 0.9em; */}
    ${"" /* margin-top: 10px; */}
  }
`

const HeaderWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 950px;
  margin: 0 auto 30px;
`
