import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import React from "react"
import FuboBanner from "src/components/common/FuboBanner"
import { StyledSectionContainer } from "src/components/common/StyledSectionContainer"
import styled from "styled-components"

import GooglePlayIcon from "src/assets/images/apps/google-play-store.png"
import AppleStoreIcon from "src/assets/images/apps/apple-tv-btn.svg"
import AmazonLogo from "src/assets/images/apps/amazon-logo.png"

const ChannelsSection = ({
  seriesName,
  title,
  appStoreUrl,
  googlePlayUrl,
  amazonVideoUrl,
}) => {
  const list = [
    {
      name: "Sling",
      image: "distributors/sling.jpg",
    },
    {
      name: "Vidaa",
      image: "distributors/vidaa.jpg",
    },
    {
      name: "Tubi",
      image: "distributors/tubi.jpg",
    },
    {
      name: "Tablo TV",
      image: "distributors/tablo.jpg",
    },
    {
      name: "Plex",
      image: "distributors/plex.jpg",
    },
    {
      name: "Freevee",
      image: "distributors/freevee.jpg",
    },
    {
      name: "LG Channels",
      image: "distributors/lg.jpg",
    },
    {
      name: "Vizeo Watch Free+",
      image: "distributors/vizio.jpg",
    },
  ]

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)
  const imageMap = data.allFile.edges.reduce((acc, edge) => {
    if (edge.node.childImageSharp) {
      acc[edge.node.relativePath] = edge.node.childImageSharp.gatsbyImageData
    }
    return acc
  }, {})
  return (
    <StyledChannelsSection>
      {(appStoreUrl || googlePlayUrl || amazonVideoUrl) && (
        <AppStoreContainer>
          <h3>{title || "Also Available On"}</h3>
          <div>
            {googlePlayUrl && (
              <a href={googlePlayUrl} target="_blank" rel="noreferrer">
                <img
                  id="google-play"
                  src={GooglePlayIcon}
                  alt={"Google Play"}
                />
              </a>
            )}

            {amazonVideoUrl && (
              <a href={amazonVideoUrl} target="_blank" rel="noreferrer">
                <img id="amazon" src={AmazonLogo} alt={"Amazon available on"} />
              </a>
            )}
            {appStoreUrl && (
              <a href={appStoreUrl} target="_blank" rel="noreferrer">
                <img
                  id="apple-store"
                  src={AppleStoreIcon}
                  alt={"Apple Store"}
                />
              </a>
            )}
          </div>
        </AppStoreContainer>
      )}

      <h2>
        <StaticImage
          src="../../../../src/assets/images/mec-logo-dark.png"
          alt="Maximum Effort Channel"
          className="mec-logo"
        />{" "}
        is Available On{" "}
        <StaticImage
          src="https://assets-imgx.fubo.tv/marketing/lp/images/logos/Fubo_Primary_Logo_BK_RGB.png?w=300&ch=width&auto=format,compress"
          alt="Fubo"
          className="fubo-logo"
        />
      </h2>
      <FuboBanner />
      {!(appStoreUrl || googlePlayUrl) && (
        <h3>{title || "Also Available On"}</h3>
      )}
      <div className="channels-section">
        {list.map((item, index) => {
          const imageData = imageMap[item.image]
          return (
            <div className="image" key={index}>
              {imageData ? (
                <GatsbyImage image={getImage(imageData)} alt={item.name} />
              ) : null}
            </div>
          )
        })}
      </div>
    </StyledChannelsSection>
  )
}

export default ChannelsSection

const StyledChannelsSection = styled(StyledSectionContainer)`
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    padding: 50px 10px;
  }

  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .mec-logo {
      width: 60px;
    }

    .fubo-logo {
      width: 100px;
      margin-bottom: 10px;
    }

    @media (max-width: 768px) {
      font-size: 24px;
      .mec-logo {
        width: 50px;
      }

      .fubo-logo {
        width: 80px;
        margin-bottom: 8px;
      }
    }
  }
  .channels-section {
    color: rgb(36, 36, 36);
    max-width: 625px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    .image {
      width: 100%;
      border-radius: 11px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
`
const AppStoreContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 40px auto 20px;
    a {
      margin: 0 auto 30px;
      @media (min-width: 430px) {
        margin: 0 30px 20px;
      }
    }
    img {
      object-fit: contain;
      height: 60px;
      object-position: center;
      margin: auto;
    }
  }
`
