import React from "react"
import { StyledSectionContainer } from "src/components/common/StyledSectionContainer"
import styled from "styled-components"

const programmingAssetsUrl =
  "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/content/"

const imgxParams = "?ch=width&auto=format,compress"

const list = [
  {
    name: "uefa-nations-league",
    image: "uefa-nations-league.jpg",
  },
  {
    name: "pfl-challenger",
    image: "pfl-challenger.jpg",
  },
  {
    name: "lights-out",
    image: "lights-out.jpg",
  },
  {
    name: "fcf",
    image: "fcf.jpg",
  },
  {
    name: "premier-rugby",
    image: "premier-rugby.jpg",
  },
  {
    name: "liga-endesa",
    image: "liga-endesa.jpg",
  },
  {
    name: "pfl",
    image: "pfl.jpg",
  },
  {
    name: "ultimate-strongman",
    image: "ultimate-strongman.jpg",
  },
  {
    name: "400-thunder",
    image: "400-thunder.jpg",
  },
  {
    name: "flex-fight",
    image: "flex-fight.jpg",
  },
  {
    name: "triton-fights",
    image: "triton-fights.jpg",
  },
  {
    name: "wpt",
    image: "wpt.jpg",
  },
  {
    name: "bare-knuckle",
    image: "bare-knuckle.jpg",
  },
  {
    name: "m1-global",
    image: "m1-global.jpg",
  },
  {
    name: "stadium",
    image: "stadium.jpg",
  },
  {
    name: "banner-promotions",
    image: "banner-promotions.jpg",
  },
  {
    name: "world-darts",
    image: "world-darts.jpg",
  },
]

const ProgrammingSection = () => {
  return (
    <StyledProgrammingSection>
      <h2>Top Sports Programming</h2>
      <div className="programming-section">
        {list.map((item, index) => (
          <div className="image" key={index}>
            <img
              src={programmingAssetsUrl + item.image + imgxParams}
              alt={item.name}
            />
          </div>
        ))}
      </div>
      <p>
        We’re teaming up with some of the best content partners to bring you the
        most compelling sports movies, shows, live events and more.
      </p>
    </StyledProgrammingSection>
  )
}

export default ProgrammingSection

const StyledProgrammingSection = styled(StyledSectionContainer)`
  background: #000;
  color: #fff;
  position: relative;
  padding-top: 75px;
  padding-bottom: 75px;
  padding-left: 60px;
  padding-right: 60px;
  gap: 0;
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  &::before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    background: radial-gradient(#fff, transparent 70%);
    opacity: 0.35;
  }
  > * {
    z-index: 1;
  }
  h2 {
    margin-bottom: 70px;
  }
  .programming-section {
    max-width: 930px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 43px 54px;
    margin-bottom: 60px;
    .image {
      width: 110px;
      img {
        width: 100%;
        border-radius: 50%;
      }
    }
    @media (max-width: 600px) {
      gap: 10px;
      .image {
        width: 90px;
      }
    }
  }
  p {
    color: #a7aaaa;
  }
`
