import React, { Component } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import moment from "moment"

class CustomSlide extends Component {
  render() {
    const { index, show, ...props } = this.props

    const imgSrc =
      show.image16x9 !==
      "https://metadata.fubotv.net/fubonetworks/schedule/v3/image/fbsn/en-US/FBSNREALMADR20211202/16x9"
        ? show.image16x9
        : "images/backup-poster.jpeg"

    return (
      <Slide>
        <ShowImgWrapper>
          <ShowImg {...props} src={imgSrc} alt={show.title} loading="lazy" />
        </ShowImgWrapper>
        <ShowText>
          <ShowTime>{formattedDate(show.airings.new.startDate)}</ShowTime>
          <ShowTitle>{show.title}</ShowTitle>
        </ShowText>
      </Slide>
    )
  }
}

function formattedDate(date) {
  return moment(date).calendar()
}

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className + " arrow right"}
      style={{ ...style }}
      onClick={onClick}
    >
      <svg width="11" height="40" viewBox="0 0 24 40">
        <title>icon-caret-right</title>
        <desc>Created with Sketch.</desc>
        <g
          id="USA-LP"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Welcome-v2---US-1600"
            transform="translate(-1542.000000, -3682.000000)"
            fill="rgba(0, 0, 0, 0.7)"
          >
            <g
              id="Content-Carousel"
              transform="translate(0.000000, 3102.000000)"
            >
              <g id="Group-14" transform="translate(16.000000, 568.000000)">
                <g
                  id="32px/padded/outlined/icon-chevron-right"
                  transform="translate(1504.000000, 0.000000)"
                >
                  <g
                    id="32px/unpadded/outlined/icon-chevron-right"
                    transform="translate(22.000000, 12.000000)"
                  >
                    <path
                      d="M23.3379295,3.48660549 C24.1589495,2.74768745 24.2255063,1.48310778 23.4865883,0.662087737 C22.7476703,-0.158932307 21.4830906,-0.225489132 20.6620705,0.513428907 L0.662070537,18.5134289 C-0.220690179,19.3079136 -0.220690179,20.6921208 0.662070537,21.4866055 L20.6620705,39.4866055 C21.4830906,40.2255235 22.7476703,40.1589667 23.4865883,39.3379467 C24.2255063,38.5169266 24.1589495,37.2523469 23.3379295,36.5134289 L4.98969423,20.0000172 L23.3379295,3.48660549 Z"
                      id="shape"
                      transform="translate(12.000009, 20.000017) scale(-1, 1) translate(-12.000009, -20.000017) "
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className + " arrow left"}
      style={{ ...style }}
      onClick={onClick}
    >
      <svg width="11" height="40" viewBox="0 0 24 40">
        <title>icon-caret-left</title>
        <desc>Created with Sketch.</desc>
        <g
          id="USA-LP"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Welcome-v2---US-1600"
            transform="translate(-34.000000, -3682.000000)"
            fill="rgba(0, 0, 0, 0.7)"
          >
            <g
              id="Content-Carousel"
              transform="translate(0.000000, 3102.000000)"
            >
              <g id="Group-14" transform="translate(16.000000, 568.000000)">
                <g id="32px/padded/outlined/icon-chevron-left">
                  <g
                    id="32px/unpadded/outlined/icon-chevron-left"
                    transform="translate(18.000000, 12.000000)"
                  >
                    <path
                      d="M23.3379295,3.48660549 C24.1589495,2.74768745 24.2255063,1.48310778 23.4865883,0.662087737 C22.7476703,-0.158932307 21.4830906,-0.225489132 20.6620705,0.513428907 L0.662070537,18.5134289 C-0.220690179,19.3079136 -0.220690179,20.6921208 0.662070537,21.4866055 L20.6620705,39.4866055 C21.4830906,40.2255235 22.7476703,40.1589667 23.4865883,39.3379467 C24.2255063,38.5169266 24.1589495,37.2523469 23.3379295,36.5134289 L4.98969423,20.0000172 L23.3379295,3.48660549 Z"
                      id="shape"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

const ScheduleCarousel = ({ showsArray }) => {
  const SliderSettings = {
    dots: false,
    speed: 300,
    centerMode: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: false,
    slidesToShow: 5,
    touchThreshold: 20,
    infinite: false,
    slidesToScroll: 1,
    adaptiveHeight: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <ShowCarouselWrapper>
      <CarouselWrapper>
        <CarouselContain>
          <Slider {...SliderSettings}>
            {showsArray.map((s, index) => (
              <CustomSlide key={index} show={s} />
            ))}
          </Slider>
        </CarouselContain>
      </CarouselWrapper>
    </ShowCarouselWrapper>
  )
}

export default ScheduleCarousel

const ShowCarouselWrapper = styled.div`
  color: #fff;
  padding: 0;
  transition: all 2s;
  position: relative;

  .slick-slider {
    padding-bottom: 20px;
    max-width: 1120px;
    margin: 0 auto;
    position: inherit;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    height: 300px;
    overflow-y: hidden;
  }

  .slick-slide {
    &:focus {
      outline: none !important;
    }
  }

  .arrow {
    width: 30px;
    height: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #fff;
    cursor: pointer;
    z-index: 10001;
    margin: auto;
    position: absolute;
    top: 86px;
    cursor: pointer;
    box-shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.6);

    &:hover,
    &:focus {
      background-color: #fff;
    }

    &:before {
      display: none;
    }

    @media screen and (max-width: 600px) {
      width: 20px;
      height: 20px;
    }
  }

  .arrow.right {
    right: 7px;
  }
  .arrow.left {
    left: 7px;
  }
`

const CarouselWrapper = styled.div`
  @media only screen and (max-width: 1400px) {
    margin-top: 10px;
  }
`

const CarouselContain = styled.div`
  overflow: hidden;
`

const Slide = styled.div`
  padding: 1.5em 0.5em 3em;
  width: 220px;
  flex-shrink: 0;
`

const ShowImgWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  background: black;
  position: relative;
  overflow: hidden;
  margin-bottom: 8px;
`

const ShowImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`

const ShowText = styled.div`
  padding: 10px 15px 15px;
`

const ShowTime = styled.div`
  color: rgb(196, 192, 184);
  margin-bottom: 8px;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  text-transform: uppercase;
`

const ShowTitle = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 18px;
  max-height: 36px;
`
