import React from "react"
import styled from "styled-components"

const YoutubeIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 159 110"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m154 17.5c-1.82-6.73-7.07-12-13.8-13.8-9.04-3.49-96.6-5.2-122 0.1-6.73 1.82-12 7.07-13.8 13.8-4.08 17.9-4.39 56.6 0.1 74.9 1.82 6.73 7.07 12 13.8 13.8 17.9 4.12 103 4.7 122 0 6.73-1.82 12-7.07 13.8-13.8 4.35-19.5 4.66-55.8-0.1-75z"
      fill="#f00"
    />
    <path d="m105 55-40.8-23.4v46.8z" fill="#fff" />
  </svg>
)

const YoutubeButton = ({ link, customCSS, ...props }) => {
  return (
    <StyledYoutubeButton href={link} css={customCSS} {...props}>
      <div className="youtube-button__icon">
        <YoutubeIcon />
      </div>
      <div className="youtube-button__text">Watch Now</div>
    </StyledYoutubeButton>
  )
}

export default YoutubeButton

const StyledYoutubeButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 11px 30px;
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 150px;
  color: rgba(255, 0, 0, 1);
  font-weight: 700;
  text-decoration: none;
  .youtube-button__icon {
    width: 30px;
    svg {
      width: 100%;
      display: block;
    }
  }
`
